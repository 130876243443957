@import 'partials/_mixins';
//==========================================================================
/*! RESETS */
//==========================================================================
@import 'partials/_normalize';

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	transform-origin: center;
	
	* {
		box-sizing: inherit;
		transform-origin: inherit;
		// color: inherit;
		text-decoration: inherit;
	}
	img, svg, canvas {
		width: 100%;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
	blockquote, cite {
		margin: 0;
		font-style: normal;
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
	address {
		font-style: normal;
	}


	.text-replace {
		position: absolute;
		top:0; left: 0;
		opacity: 0;
		height: 0; width: 0;
		overflow: hidden;
		pointer-events: none;
	}
}

// test
//==========================================================================
/*! VARS */
//==========================================================================

//--------------------------------------------------------------------------
/*! - Dimensions */
//--------------------------------------------------------------------------
$navBar-H: 70px;
$con-maxW: 1362px;
//--------------------------------------------------------------------------
/*! - Colors */
//--------------------------------------------------------------------------
$c-white: 			#FBFBFB;
$c-black: 			#002248;
$c-orange:			#ff681f;
$c-green-reg:		#91B616;
$c-green-light:		#BAD243;
$c-green-pale:		#DEF49B;
$c-blue-dark:		#212B2B;
$c-green-mint:		#03aba3;
$c-red: 			#fb3b43;
.test {}
//--------------------------------------------------------------------------
/*! - Fonts */
//--------------------------------------------------------------------------
// $f-f-body: 		Noto Sans, sans-serif;
// $f-f-title: 		Noto Sans, sans-serif;
$f-f-body: 		'Poppins', sans-serif;
$f-f-title: 		'Poppins', sans-serif;



//==========================================================================
/*! Site Setup */
//==========================================================================
body, html {
	height: 100%;
	width: 100%;
	@include color('background', $c-white, 1);
	//overflow-x: hidden;
}

a {
	color:$c-black;
}
a:visited { text-decoration: none; color:$c-black; }
a:hover { text-decoration: none; color:$c-black; }
a:focus { text-decoration: none; color:$c-black; }
a:hover, a:active { text-decoration: none; color:$c-black }


//--------------------------------------------------------------------------
/*! - Typeography */
//--------------------------------------------------------------------------
body {
	font-family: $f-f-body;
	
	font-size: 16px;
	line-height: 1.4;
	

	
	p {
		margin: 1em 0;
	}
}

//==========================================================================
/*! .sec */
// Sections and major components
//==========================================================================
.sec {
	position: relative;
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	padding: 5%;
	overflow-x: hidden;

	color:$c-black;
	
	@include bp(sm) {
		// padding: 60px 20px;
	}
	@include bp(md) {
		padding: 10% 16% 6%;
	}
	@include bp(lg) {
		// padding: 160px 14% 100px;
	}
}

//--------------------------------------------------------------------------
/*! - .sec-parallax */
//--------------------------------------------------------------------------
.sec-parallax {
	position: relative;
	overflow: hidden;
	max-height: 40%;
	min-height: 40%;
	
	@include bp(sm) {
		max-height: 50%;
		min-height: 50%;
	}

	@include bp(md) {
		max-height: 100%;
		min-height: 100%;
	}
	
	&__layer {
		position: absolute;
		top:0; left:-25%; //positions it half way for horizontal scroll
		width: 150%;
		height: 100%;
		background-size:cover;
		background-repeat:no-repeat;
		background-position: center;
	}
}

// image captions for each parallax section
.parallax__image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 8px;
    color: white;
    margin-left: .5em;
    margin-bottom: .5em;
	@include bp(sm) {
		font-size: 12px;
	}
	@include bp(md) {
		font-size: 16px;
	}
	@include bp(lg) {
		// font-size: 20px;
	}	
}

//--------------------------------------------------------------------------
/*! - .sec-header */
//--------------------------------------------------------------------------
.sec-header {
	// padding-bottom: 160px; // Compensate cnetral alignment of the logo for the scroll button
	
	justify-content:center;

    // padding: 10% 16% 6%;

    padding:0;

	@include bp(sm) {
		// padding:7% 10% 6%;
	}    

	&__logo {

		width: 80%;
		height: auto;
		// max-width: 680px;
		// padding: 30px;
		@include color('fill', $c-white,0.65);

		.logo-podge-full {
			opacity:1;
		}

		@include bp(sm) {
			width: 40%;
		}
		@include bp(md) {
			width: 36%;
		}
		@include bp(lg) {

		}		

	}
	&__scroll {
		position: absolute;
		bottom:0; left:0; right:0;
		text-align: center;
		padding-bottom: 5%;
		@include bp(sm) {
		    padding-bottom: 3%;
		}
		@include bp(md) {
			padding-bottom: 2%;
		}
		@include bp(lg) {
			padding-bottom: 1%;
		}		
	}
}

//--------------------------------------------------------------------------
/*! - .sec-history */
//--------------------------------------------------------------------------
.sec-history {
	
	// .con-holder {
	// 	background-image: url(../img/bkgs/bkg-history-textbox-desk.svg);
	// 	background-repeat: no-repeat;
	// 	background-size: contain;
	// 	background-position: center; 
	// }
	.con-textBox-top {
		line-height:0;
		width:100%;
		margin-bottom:-1px;
		@include bp(md) {
			margin-bottom:-1px;	
		}
	}
	.con-textBox-bottom {
		line-height:0;
		width:100%;
		margin-top:-1px;
		@include bp(md) {
			margin-top:-1px;
		}
	}
	

	.con-holder {
		@include color('background', #ff681f, 1);
	}


	&__content {	
		margin: 0 auto;	
		@include bp(sm) {

		}
		@include bp(md) {

		}
		@include bp(lg) {

		}
	}
}

//--------------------------------------------------------------------------
/*! - .sec-menu */
//--------------------------------------------------------------------------
.sec-menu {
	
	.con-textBox-bottom {
		line-height:0;
		width:100%;
		margin-top:-1px;

		@include bp(md) {
			margin-top:-1px;
		}
	}
	.con-holder {
		@include color('background', #fbc323, 1);
		@include bp(md) {
			padding: 3% 6% 2%;
		}
	}

	.con-menu-rule {
		height:2px;
		width:6%;
		border-bottom:2px solid $c-black;
	}

	&__content {
		margin: 0 -20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-content: stretch;
		align-items: stretch;
	}
	
	&__grid {
		// width: 100%; 
		max-width: $con-maxW;
		
		@include bp(md) {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-content: stretch;
			align-items: stretch;
			
			margin: 0 -20px;
		}
	}
	&__col {
		padding:0 10px;
		width: 100%;
		@include bp(md) {
			
			//height: 100%;
			min-width: calc(100%/3);
			max-width: calc(100%/3);
			display: flex;
			flex-direction: row;
			align-content: stretch;
			// align-items: stretch;
			align-items:flex-start;
		}
		
		@include bp(md) {
			padding:0 20px;
		}
	}
}

//--------------------------------------------------------------------------
/*! - .sec-event */
//--------------------------------------------------------------------------
.sec-event {

	// .con-holder-holder {
	// 	display:flex;
	// 	flex-direction:row;
	// }

	.sec-overlay {
		pointer-events: none;
		display:none;

		@include bp(md) {

			display:block;

			background-image: url('../img/bkgs/frg-event-desk.png');
		    background-repeat: no-repeat;
		    background-size: 100% 100%;
		    background-position: center center;
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;		
		}

	}

	.con-holder {
		// @include color('background', #ff681f, 1);
		@include bp(md) {
			padding: 3% 12% 2%;
		}
	}

	.con-event-rule {
		height:2px;
		width:6%;
		border-bottom:2px solid $c-black;
		display:none;
		@include bp(sm) {
			display:block;
			margin: 1.5em 0;
		}

	}

	.con-holder {

		background-color: $c-orange;

		@include bp(sm) {
			background-color: transparent;
			background-image:url('../img/bkgs/bkg-event-textbox-desk.png');
			background-size: 100% 100%;
			background-repeat:no-repeat;
			background-position: center;
			width:100%;
		}


	}

	// @include bp(sm) {
	// 	padding: 60px 20px;
	// }
	// @include bp(md) {
	// 	padding: 60px 18%;
	// }
	// @include bp(lg) {
	// 	padding: 60px 10%;
	// }

	&__content {
		width: 100%;
		// max-width: 900px;
	
		@include bp(sm) {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-content: center;
			align-items: center;
		}
		@include bp(md) {
			position:relative;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-content: center;
			align-items: flex-start;
		}		
	}
	
	&__map {
		margin: 0 auto 0 auto;
		display: block;
		width: 100%;
		max-width: 80vw;
		padding: 20px;
		
		@include bp(sm) {
			width: 60%;
		}
		@include bp(md) {
			// width: 50%;
		}
		@include bp(lg) {
			width: 60%;
		}
	}
	
	&__address {
		margin-top: 10px;
		@include bp(sm) {
			width: 40%;
			padding-left: 20px;
		}
		@include bp(md) {
			// width: 50%;
			padding-left: 20px;
			margin-top: 20px;
		}
		@include bp(lg) {
			width: 40%;
			// padding-left: 40px;
			// margin-top:60px;
			padding:0;
			margin: 0;
			margin-left: 20px;
			margin-top:20px;
		}		
	}
}
//--------------------------------------------------------------------------
/*! - .sec-players */
//--------------------------------------------------------------------------
.sec-players {

	// padding:60px 0;

	.con-holder {
		// padding:0;
		// width:50%;
	}

	&__slider {
		position: relative;
		width: calc(100% + 40px);
		margin:40px 0;
		// margin: 128px -20px 170px;
	}
}

//--------------------------------------------------------------------------
/*! - .sec-supporters */
//--------------------------------------------------------------------------
.sec-supporters {
	
	.con-holder {
		width:100%;
		// margin-bottom:80px;
	}

	&__grid {
		width: 100%; 
		max-width: $con-maxW;
		
		@include bp(md) {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-content: stretch;
			align-items: stretch;
			flex-wrap: wrap;
			margin: 0 -20px;
		}
	}
	
	&__col {
		padding:10px;
		width: 100%;
		margin: 0 auto;
		
		@include bp(sm) {
			position: relative;
			padding:20px;
			
			width: 70%
		}
		
		@include bp(md) {
			width: calc(100%/3);
			display: flex;
			justify-content: flex-start;
			align-content: stretch;
			align-items: stretch;
			flex-direction: row;
			margin: 0;
		}
	}
}

//--------------------------------------------------------------------------
/*! - .sec-legends */
//--------------------------------------------------------------------------
.sec-legends {
	padding-bottom: 150px;
	
	
	&__grid {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		width: 100%;
		// max-width: $con-maxW;
		@include bp(sm) {
			flex-direction: column;
		}
		@include bp(md) {
			margin-bottom:60px;
		}
	}
	&__col {
		width: 100%;
		@include bp(sm) {
			flex-direction: column;
			width: 100%;
		}
		@include bp(md) {
			display:flex;
			justify-content:center;
			align-items:center;
			flex-direction: column;
			width: 100%;
		}
	}
}

//--------------------------------------------------------------------------
/*! - .sec- background Images */
//--------------------------------------------------------------------------
.sec {
	background-size: cover;
	background-position: center center;

	@include color('background', $c-white,1);

	background-size:cover;
	background-position:center;
	background-repeat: no-repeat;

	&-header {
		background-image: url('../img/bkgs/bkg-header-mob.png'); 
	}
	&-history {
		background-image: url('../img/bkgs/bkg-history-mob.png');
	}
	&-menu {
		background-image: url('../img/bkgs/bkg-menu-mob.png');
	}
	&-event {
		background-image: url('../img/bkgs/bkg-event-mob.png');
	}
	&-players {
		background-image: url('../img/bkgs/bkg-players-mob.png');

		@include bp(md) {
			padding:0;
			margin:0;
		}
	}		
	&-supporters {
		
	}
	&-legends {

	}
	
	&-parallax {

		&--001 {
			background-image: url('../img/bkgs/bkg-plax001-mob.jpg');
			
			.p1l1 {
				background-image:url('../img/parallax/p1_a-mob.png');
			}
			.p1l2 {
				background-image:url('../img/parallax/p1_b-mob.png');
			}
			.p1l3 {
				background-image:url('../img/parallax/p1_c-mob.png');
			}
		}
		&--002 {
			background-image: url('../img/bkgs/bkg-plax002-mob.jpg');
			
			.p2l1 {
				background-image:url('../img/parallax/p2_a-mob.png');
			}
			.p2l2 {
				background-image:url('../img/parallax/p2_b-mob.png');
			}

		}
		&--003 {
			background-image: url('../img/bkgs/bkg-plax003-mob.jpg');
			
			.p3l1 {
				background-image:url('../img/parallax/p3_a-mob.png');
			}
			.p3l2 {
				background-image:url('../img/parallax/p3_b-mob.png');
			}
			.p3l3 {
				background-image:url('../img/parallax/p3_c-mob.png');
			}
			.p3l4 {
				background-image:url('../img/parallax/p3_d-mob.png');
			}
		}
		&--004 {
			background-image: url('../img/bkgs/bkg-plax004-mob.jpg');
			
			.p4l1 {
				background-image:url('../img/parallax/p4_a-mob.png');
			}
			.p4l2 {
				background-image:url('../img/parallax/p4_b-mob.png');
			}
			.p4l3 {
				background-image:url('../img/parallax/p4_c-mob.png');
			}
			.p4l4 {
				background-image:url('../img/parallax/p4_d-mob.png');
			}
			
		}
		&--005 {
			background-image: url('../img/bkgs/bkg-plax005-mob.jpg');
			background-position: center center;
			
			.p5l1 {
				background-image:url('../img/parallax/p5_a-mob.png');
			}
			.p5l2 {
				background-image:url('../img/parallax/p5_b-mob.png');
			}
			.p5l3 {
				background-image:url('../img/parallax/p5_c-mob.png');
			}
		}
	}
	
	@include bp(sm) {
		
		&-header {
			background-image: url('../img/bkgs/bkg-header-desk.png'); 
		}
		
		&-history {
			background-position: right bottom;
			background-image: url('../img/bkgs/bkg-history-desk.png'); 
		}
		
		&-menu {
			
			background-image: url('../img/bkgs/bkg-menu-desk.png');
		}
		
		&-event {
			
			background-image: url('../img/bkgs/bkg-event-desk.png'); 
		}
		
		&-supporters {
			// padding-top:74px;
			// padding-bottom: 164px;
			background-position: left bottom;
			background-image: url('../img/bkgs/bkg-supporters-desk.png');
			background-size: cover;
			background-repeat: no-repeat;
		}
		
		&-players {
			
			background-image: url('../img/bkgs/bkg-players-desk.png'); 
		}
		
		&-parallax {

			&--001 {
				background-image: url('../img/bkgs/bkg-plax001-desk.jpg');
				
				.p1l1 {
					background-image:url('../img/parallax/p1_a-desk.png');
				}
				.p1l2 {
					background-image:url('../img/parallax/p1_b-desk.png');
				}
				.p1l3 {
					background-image:url('../img/parallax/p1_c-desk.png');
				}
			}
			&--002 {
				background-image: url('../img/bkgs/bkg-plax002-desk.jpg');
				
				.p2l1 {
					background-image:url('../img/parallax/p2_a-desk.png');
				}
				.p2l2 {
					background-image:url('../img/parallax/p2_b-desk.png');
				}
			}
			&--003 {
				background-image: url('../img/bkgs/bkg-plax003-desk.jpg');
				
				.p3l1 {
					background-image:url('../img/parallax/p3_a-desk.png');
				}
				.p3l2 {
					background-image:url('../img/parallax/p3_b-desk.png');
				}
				.p3l3 {
					background-image:url('../img/parallax/p3_c-desk.png');
				}
				.p3l4 {
					background-image:url('../img/parallax/p3_d-desk.png');
				}
			}
			&--004 {
				background-image: url('../img/bkgs/bkg-plax004-desk.jpg');
				
				.p4l1 {
					background-image:url('../img/parallax/p4_a-desk.png');
				}
				.p4l2 {
					background-image:url('../img/parallax/p4_b-desk.png');
				}
				.p4l3 {
					background-image:url('../img/parallax/p4_c-desk.png');
				}
				.p4l4 {
					background-image:url('../img/parallax/p4_d-desk.png');
				}
				
			}
			&--005 {
				background-image: url('../img/bkgs/bkg-plax005-desk.jpg');
				
				.p5l1 {
					background-image:url('../img/parallax/p5_a-desk.png');
					background-position: center;
					// background-size:90%;
				}
				.p5l2 {
					background-image:url('../img/parallax/p5_b-desk.png');
					background-position: center;
					// background-size:90%;
				}
				.p5l3 {
					background-image:url('../img/parallax/p5_c-desk.png');
					background-position: center;
					// background-size:90%;
				}
			}
		}
	}
	@include bp(md) {
		
		&-parallax {


			&--001 {
				background-image: url('../img/bkgs/bkg-plax001-desk.jpg');
				
				.p1l1 {
					background-image:url('../img/parallax/p1_a-desk.png');
				}
				.p1l2 {
					background-image:url('../img/parallax/p1_b-desk.png');
				}
				.p1l3 {
					background-image:url('../img/parallax/p1_c-desk.png');
				}
			}
			&--002 {
				background-image: url('../img/bkgs/bkg-plax002-desk.jpg');
				
				.p2l1 {
					background-image:url('../img/parallax/p2_a-desk.png');
				}
				.p2l2 {
					background-image:url('../img/parallax/p2_b-desk.png');
				}
			}
			&--003 {
				background-image: url('../img/bkgs/bkg-plax003-desk.jpg');
				
				.p3l1 {
					background-image:url('../img/parallax/p3_a-desk.png');
				}
				.p3l2 {
					background-image:url('../img/parallax/p3_b-desk.png');
				}
				.p3l3 {
					background-image:url('../img/parallax/p3_c-desk.png');
				}
			}
			&--004 {
				background-image: url('../img/bkgs/bkg-plax004-desk.jpg');
				
				
				.p4l1 {
					background-image:url('../img/parallax/p4_a-desk.png');
					// background-position: center;
					// background-size:90%;
				}
				.p4l2 {
					background-image:url('../img/parallax/p4_b-desk.png');
					// background-position: center;
					// background-size:90%;
				}
				.p4l3 {
					background-image:url('../img/parallax/p4_c-desk.png');
					// background-position: center;
					// background-size:90%;
				}
				.p4l4 {
					background-image:url('../img/parallax/p4_d-desk.png');
					// background-position: center;
					// background-size:90%;
				}
				
			}
			&--005 {
				background-image: url('../img/bkgs/bkg-plax005-desk.jpg');
				
				.p5l1 {
					background-image:url('../img/parallax/p5_a-desk.png');
					// background-position: 20% center;
					// background-size:80%;
				}
				.p5l2 {
					background-image:url('../img/parallax/p5_b-desk.png');
					// background-position: 20% center;
					// background-size:80%;
				}
				.p5l3 {
					background-image:url('../img/parallax/p5_c-desk.png');
					// background-position: 20% center;
					// background-size:80%;
				}
			}
		}
	}
}

//==========================================================================
/*! .con */
// Content Modules
//==========================================================================
//--------------------------------------------------------------------------
/*! - .con-title */
//--------------------------------------------------------------------------
.con {
	&-textBox {

		text-align:center;

		@include bp(md) {
			margin: .8em 0;
		}

		&__footer {
			display: block;
			text-align: center;
		}
	}
	&-holder {
		padding: 4% 8%;
		width:100%;

		@include bp(md) {
			padding: 2% 10% 5%;
		}		
	}
	&-rule {
		border-bottom:2px solid $c-black;
	}
	&-title {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		align-content: center;
		text-align: center;
		padding: 0 .3em .3em;
		margin: 0;
		font-size: 28px;
		line-height: 1.2;

		text-transform: uppercase;

		letter-spacing: 2px;

		margin-top: 24px;

		&__text {
			line-height:1;
		}

		// @include bp(sm) {
		// 	font-size: 75px;
		// }
		
		@include bp(md) {
			font-size: 50px;
			// padding: 12px 0 6px;
			line-height:.8;

		}
		
		&__deco {
			display: inline-block;
			height: .6em;
			line-height:0;
			padding-bottom:.2em;

			@include bp(sm) {
				height: .8em;
				padding-bottom:0;
			}


			&--left {
				left:0;
			}
			&--right {
				right:0;
			}
			
			svg {
				height: 100%;
				width: auto;
			}
		}
		
	}
	&-subTitle {
		font-size: 16px;
		text-align: center;
		margin: .6em 0;
		
		@include bp(sm) {
			// font-size: 24px;
			margin: 1.4em 0;
		}

		@include bp(md) {
			font-size: 28px;
			font-weight:normal;
			margin: 1em 0;
		}
		
		// br {
		// 	display: none;
			
		// 	@include bp(sm) {
		// 		display: inline-block;
		// 	}
		// }
	}
}

//--------------------------------------------------------------------------
/*! - .con-holder */
//--------------------------------------------------------------------------
.con-holder {
	// padding: 0;
	// width:100%;

	// @include bp(sm) {
	// 	padding: 0 100px;
	// }

	// padding: 20px 20px;
	// @include color('background', #c4c4c4, 0.8);
	
	// @include bp(sm) {
	// 	padding: 40px 40px;
	// }
	
	// @include bp(md) {
	// 	padding: 40px 90px;
	// }
	
	// &__footer {
	// 	display: block;
	// 	text-align: right;
	// 	margin: 1.5em;
	// 	font-weight: 700;
	// }
}

//--------------------------------------------------------------------------
/*! - .con-textBox */
//--------------------------------------------------------------------------
.con-textBox {
	// padding: 20px 20px;
	// // @include color('background', #ff681f, 1);
	// text-align:center;
	
	// @include bp(sm) {
	// 	padding: 40px 40px;
	// }
	
	// @include bp(md) {
	// 	padding: 30px 40px 16px;
	// }
	
	// &__footer {
	// 	display: block;
	// 	text-align: center;
	// 	// margin: 1.5em;
	// 	// font-weight: 700;
	// }
}
//--------------------------------------------------------------------------
/*! - .con-menuPod */
//--------------------------------------------------------------------------
.con-menuPod {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	padding: 20px 10px 0;
	font-family: $f-f-title;
	text-align: center;
	
	@include bp(sm) {
		// padding: 18px 20px 0px 20px;
	}
	
	@include bp(md) {
		// text-align: center;
		// padding: 18px 40px 0px 40px;
		align-items:flex-start;
	}
	
	&__header {
		display:flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
	}
	
	&__dot {
		
		content: '';
		position: relative;
		// top:45%;
		display: block;
		min-width: 8px;
		min-height: 8px;
		border-radius: 100%;
		@include color('background', $c-black,1);
		
		// @include bp(sm) {
		// 	position:relative;
		// 	width: 8px;
		// }
		
		// @include bp(md) {
		// 	width: 9px;
		// 	height: 9px;
		// }

		&-left {
			left:0;
			margin-right:10px;

			// @include bp(sm) {
			// 	// left:26%;
			// 	margin-right:10px;
			// }
			// @include bp(md) {
			// 	left:0px;
			// }
		}
		&-right {
			right:0;
			margin-left:10px;
			// @include bp(sm) {
			// 	// right:26%;
			// 	margin-left:10px;
			// }
			// @include bp(md) {
			// 	right:0px;
			// }
		}		
	}

	&__title {
		position: relative;
		text-align: center;
		align-self: flex-start;
		width: 100%;
		font-size: 24px;
		margin: 0 1%;
		font-weight:normal;
		white-space:nowrap;

		@include bp(sm) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			font-size: 24px;
		}
		
		@include bp(md) {
			font-size: 24px;
			// white-space:nowrap;
		}
		
		// &:before, &:after {
		// 	content: '';
		// 	position: absolute;
		// 	top:45%;
		// 	display: block;
		// 	width: 8px;
		// 	height: 8px;
		// 	border-radius: 100%;
		// 	@include color('background', $c-black,1);
			
		// 	@include bp(sm) {
		// 		position:relative;
		// 		width: 8px;
		// 	}
			
		// 	@include bp(md) {
		// 		width: 9px;
		// 		height: 9px;
		// 	}
		// }
		// &:before {
		// 	left:0;

		// 	@include bp(sm) {
		// 		// left:26%;
		// 		margin-right:10px;
		// 	}
		// 	@include bp(md) {
		// 		left:0px;
		// 	}
		// }
		// &:after {
		// 	right:0;
			
		// 	@include bp(sm) {
		// 		// right:26%;
		// 		margin-left:10px;
		// 	}
		// 	@include bp(md) {
		// 		right:0px;
		// 	}
		// }
	}
	&__item {
		width: 100%;
		@include color('border-color', $c-blue-dark	,1);
		@include color('color', $c-blue-dark,1);
		margin: 1em 0;
		font-size: 16px;
		font-weight: normal;
		
		&:last-child {
			border: none;
			padding-bottom: 0;
		}
		
		&--lrg {
			border-bottom: none;
			padding-bottom: 0;
			font-size: 18px;
			font-weight: 700;
			
			@include bp(sm) {
				font-size: 22px;
			}
			
			@include bp(md) {
				font-size: 28px;
			}
		}
	}
	&__footer {
		align-self: flex-end;
		width: 100%;
		padding: 0;
		
		@include bp(md) {
			position: absolute;
			bottom: 0; left:0;
			padding: 0 40px 20px 40px;
		}

	}
}
//--------------------------------------------------------------------------
/*! - .con-fact */
//--------------------------------------------------------------------------
.con-fact {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	text-align: left;
	margin-top: 10px;
	@include color('color', $c-blue-dark,1);
	font-size: 16px;
	font-weight: 100;
	
	br {
		display: none;
		@include bp(sm) {
			display: block;
		}
	}
	
	
 	
	&__figure {
		font-size: 2em;
		font-weight: 100;
		margin-right: 0.5em;
		
		@include bp(sm) {
			font-size: 1.5em;
		}
		
		@include bp(md) {
			font-size: 2em;
		}
	}
}
//--------------------------------------------------------------------------
/*! - .con-address */
//--------------------------------------------------------------------------
.con-address {
	margin-bottom: 1.5em;
	text-align: center;
	
	@include bp(sm) {
		text-align: left;
		margin-bottom: 0;
	}
	
	@include bp(md) {
		font-size: 16px;
	}
	
	&__title {
		font-weight: normal;
		display:flex;
		flex-direction:row;
		justify-content:center;
		align-items:center;
		white-space: nowrap;
		text-align:center;

		margin-bottom:10px;

		font-size: 18px;
		
		@include bp(sm) {
			font-size: 17px;
			// text-align:left;
			justify-content:flex-start;
		}
		@include bp(md) {
			font-size: 15px;
		}
		@include bp(lg) {
			font-size: 21px;
		}		
		

		&:before, &:after {
			position:relative;
			content: '';
			top:45%;
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			@include color('background', $c-black,1);
			
			@include bp(sm) {
				width: 8px;
			}
			
			@include bp(md) {
				// margin-top:7px;
			}
			@include bp(lg) {
				// margin-top:12px;
			}
		}
		&:before {
			margin-right:10px;

			@include bp(sm) {
				margin-right:10px;
			}
			@include bp(md) {
				// left:0px;
			}
		}
		&:after {
			margin-left:10px;

			@include bp(sm) {
				margin-left:10px;
			}
			@include bp(md) {
				// right:0px;
			}
		}

	}
}

//--------------------------------------------------------------------------
/*! - .con-player */
//--------------------------------------------------------------------------
.con-player {
	
	margin:0 10px;
		
	.con-player-ratiowrapper {
		
		width:100%;
		padding-bottom:100%;
		position:relative;

		.con-player-holder {
			
			position:absolute;
			top:0;left:0;right:0;bottom:0;
			// @include color('background', #ff681f, 1);
		    background-size:100% 100%;
		    background-position:center;
		    background-repeat:no-repeat;
		    padding: 22px 50px;
			text-align: center;

			@include bp(lg) {
				padding: 22px 80px;
			}	
			@include bp(x-lg) {
				padding: 22px 100px;
			}

		}
		.card1 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card1.png');
		}
		.card2 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card2.png');
		}
		.card3 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card3.png');
		}
		.card4 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card4.png');
		}
		.card5 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card5.png');
		}
		.card6 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card6.png');
		}
		.card7 {
			background-image:url('http://madebytommy.com/developer/sp2018/_test/card7.png');
		}	
		.con-textBox-top {
			line-height: 0;
		    width: 100%;
		    margin-bottom:-1px; //fix random pixel gap on larger phones
		}
		.con-textBox-bottom {
			line-height: 0;
		    width: 100%;
		    margin-top:-1px; //fix random pixel gap on larger phones
		}
		
		
	}
	&__portrait {
		position: relative;
		width: 50%;
		max-width: 50vw;
		margin: 0 auto 10px auto;
		border: 4px solid;
		@include color('border-color', $c-black,1);
		border-radius: 50%;
		overflow: hidden;
		@include bp(sm) {
			margin: 0 auto 20px auto;
		}
		
		img {
			display: block;
			width: 100%; height: 100%;
		}
	}
	&__name-wrapper { //for the ::before and ::after psuedo selectors to not disappear when shrunk

		display: flex;
		justify-content: center;
		align-items: center;

		@include bp(sm) { //sizes and controls margin-bottom for .con-player__name
			font-size: 20px;
			margin-bottom: 0.5em;
			margin-right: -50px;
			margin-left: -50px; //negative margins allows ::before and ::after dots to not get squashed by small element space (caused by padding to keep within image background)
		}

		&:before, &:after {
			position:relative;
			content: '';
			top:45%;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			@include color('background', $c-black,1);
			
			@include bp(sm) {
				width: 6px;
			}
			
			@include bp(md) {
				width: 8px;
				height: 8px;
				// margin-top:5px;
			}
		}
		&:before {
			margin-right:10px;

			@include bp(sm) {
				// margin-right:10px;
			}
			@include bp(md) {
				// left:0px;
			}
		}
		&:after {
			margin-left:10px;

			@include bp(sm) {
				// margin-left:10px;
			}
			@include bp(md) {
				// right:0px;
			}
		}
	}
	&__name {
		font-family: $f-f-title;
		@include color('color', $c-black,1);
		font-size: 18px;
		margin-bottom: 0.25em;
		// @include bp(sm) {
		// 	font-size: 20px;
		// 	margin-bottom: 0.5em;
		// }

		display: flex;
		justify-content: center;
		align-items: center;



	}
	&__role {
		font-weight: 700;
		font-size: 14px;
		line-height:1.2;
		margin-bottom: 0.5em;
		@include bp(sm) {
			font-size: 12px;
			line-height:1.4;
		}
		
	}
	&__company {
		font-weight: 100;
		font-size: 14px;
		line-height:1.2;
		margin-bottom: 0.5em;	
		@include bp(sm) {
			line-height:1.4;
			font-size: 12px;
		}
	}

}

//--------------------------------------------------------------------------
/*! - .con-supportPod */
//--------------------------------------------------------------------------
.con-supportPod {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	flex-wrap: nowrap;
	align-content: stretch;
	align-content: stretch;
	height: 100%;
	position: relative;
	z-index: 10;
	width: 100%;
// 	margin: 50% 0;
	//padding: 0 40px;

	text-align:center;
 	
	
	&__inner {
		position: relative;
		z-index: 10;
		order: 2;
		width: 100%;
		height: 100%;
		padding: 0 20px;
		@include color('background', $c-orange,1);

		display: flex;
		flex-direction: column;
		align-items: center;
		
		@include bp(sm) {
			padding: 14px 40px 60px;
		}

		font-size:14px;
		line-spacing:0.7;

	}
	
	&__cap {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 0px;
		border-bottom: 70px solid $c-orange;
		border-left: 60px solid transparent;
		border-right: 60px solid transparent; 
		margin-bottom:-1px;
		
		@include bp(sm) {
			border-bottom: 100px solid $c-orange;
			border-left: 100px solid transparent;
			border-right: 100px solid transparent; 
			margin-bottom:0;
		}
		@include bp(md) {
			border-bottom: 80px solid $c-orange;
			border-left: 80px solid transparent;
			border-right: 80px solid transparent; 
			margin-bottom:0;
		}		

		// Set Sizing based on ratio to width (height should be half the width)
		// &:before {
		// 	content: '';
		// 	width:100%;
		// 	height:0;
		// 	padding-bottom: 50%;
		// 	display: block;
		// 	width: 100%;
		// 	height: 0;
		// 	padding-bottom: 50%;
		// }
		
		// // Create the semi circles
		// &:after {
		// 	position: absolute;
		// 	left:0;
		// 	content: '';
		// 	width:100%;
		// 	height:0;
		// 	padding-bottom: 50%;
		// 	display: block;
		// 	width: 100%;
		// 	height: 0;
		// 	padding-bottom: 100%;
		// 	// border-radius: 50%;
		// 	@include color('background', $c-orange,1);
		// }
		// &--top {
		// 	order: 1;
		// 	&:after {
		// 		top:0;
		// 	}
		// }
		// &--bottom {
		// 	order: 3;
		// 	&:after {
		// 		bottom:0;
		// 	}
		// }
	}
	&__logo {
		position: absolute;
		// bottom: calc(100% + 14px);
		bottom:100%;
		left: 50%;
		transform: translate(-50%, 25%);
		width: 55%;

		
	}
	&__copy {}
	&__cta {

		// position:absolute;
		// bottom:9%;
		// left:50%;

		margin-bottom:30px;

		a {
			text-decoration: underline;
			font-weight:bold;
			cursor:pointer;
		    // word-wrap: break-word;
	        white-space: nowrap;
			// margin-left:-100%;
		}


		// position: absolute;
		// top: 100%;
		// left: 50%;
		// transform: translateX(-50%);
		// max-width: 90%;
		// width: 100%;
		
		// @include bp(sm) {
		// 	top: calc(100% + 40px);
		// 	max-width: 70%;
		// }
	}
}

//--------------------------------------------------------------------------
/*! - .con-legendsGrid */
//--------------------------------------------------------------------------
.con-legendGrid {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	align-content: center;

	width: 100%; 
	max-width: $con-maxW;
	
	@include bp(sm) {
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: 64px;
	}
	
	&__legend {
		&--active {}
	}
}

//--------------------------------------------------------------------------
/*! - .con-legendPod */
//--------------------------------------------------------------------------
.con-legendPod {
	width: 100%;
	padding: 20px;
	margin-bottom: 30px;
	text-align: center;
	

	// @include bp(sm) {
	// 	width: calc(100%/4);
	// 	padding: 10px;
	// 	margin-bottom: 0;
	// }
	
	@include bp(md) {
		width: calc(100%/6);
		padding: 10px 18px;
		margin:0;
	}
	
	&__portrait {
		position: relative;
		width: 100%;
		max-width: 50vw;
		margin: 0 auto 30px auto;
		display: block;
		// border: 4px solid;
		// @include color('border-color', $c-green-reg,1);
		overflow: hidden;
		
		@include bp(md) {
			
			margin: 0;
			

			&:after {
				content:'';
				background-image:url("../img/legends/overlay.png");
				background-position:center;
				background-size:contain;
				background-repeat:no-repeat;
				position: absolute;
				top:0; left:0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: all 0.3s ease;
			}
			
			html.desktop &:hover:after, 
			&.is-active:after {
				opacity: 0.25;
			} 
		}
		
		img {
			display: block;
		}
	}
	
	&__quote {
		@include color('color', $c-black,1);
		@include bp(md) {
			display:none; //hide the names and quotes under each portrait in desktop
		}		
	}
	
	&__cite {
		display: block;
		font-size: 28px;
		font-weight: 700;
		line-height: 1.2;
		// margin-bottom:.25em;
		margin-bottom:16px;
	}
	&__job {
		display: block;
		font-size: 16px;
		font-weight: 700;
		line-height: 1.2;
		// margin-bottom:1em;
		margin-bottom:16px;
	}	
	&__quotetext {
		font-size: 16px;

		@include bp(md) {
			display: none;
			font-size: 24px;
		}
	}	
}

//--------------------------------------------------------------------------
/*! - .con-bigQuote */
//--------------------------------------------------------------------------
.con-bigQuote {
	display: none;
	
	@include bp(md) {
		display: block;
		padding: 0 20px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width:64%; //does not exceed more than 4 of the legend portrait lengths
	}

	&__holder {
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align:center;
		.con-legendPod__quote {
			display:block;
		}
		.con-legendPod__quotetext {
			display:block;
			font-size:18px;
			max-width:760px;
			min-height:250px;
		}
		.con-legendPod__cite {
			font-size:36px;
		}
		.con-legendPod__job {
			font-size:18px;
		}
	}

	&__quote_l {
		order: 2;
		width: 100%;

		display:flex;
		flex-direciton:row;
		justify-content:flex-end;
		align-items:flex-start;

		text-align:right;
		margin-right:35px;

		.con-legendPod__cite {
			font-size:36px;
		}
		.con-legendPod__job {
			font-size:18px;
		}

	}

	&__quote_r {
		order: 2;
		width: 100%;

		display:flex;
		flex-direciton:row;
		justify-content:flex-start;
		align-items:flex-start;
		margin-left:35px;

		.con-legendPod__quotetext {
			display:block;
			font-size:18px;
			max-width:760px;
			min-height:250px;
		}

	}


	&__deco {
		width: 60px;
		min-width: 60px;
		@include color('fill', $c-green-light,1);
		&--before {
			order: 1;
			margin-top: 65px;
			padding-right: 20px;
		}
		&--after {
			order: 3;
			align-self: flex-end;
			padding-left: 20px;
			transform: rotateZ(180deg);
			
		}
	}
}

//==========================================================================
/*! .nav */
// Navigation modules and major components
//==========================================================================
//--------------------------------------------------------------------------
/*! - .nav-bar */
//--------------------------------------------------------------------------
.nav-bar {
	position: fixed;
	top:0; left:0; right:0;
	z-index: 999;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-content: stretch;
	align-items: stretch;
	flex-wrap: nowrap; 
	width: 100%;
	height: $navBar-H;
	overflow-y: visible;
	padding: 0 15px 0 24px;
	@include color('background', $c-white,1);
	
	@include bp(md) {
		justify-content: space-between;
	}
	
	
	&__logo {
		z-index: 999;
		order: 1;
		display: flex;
		align-self: flex-start;
		flex-direction: row;
		justify-content: center;
		padding: 10px 0;
		position: absolute;

		left:50%;

		opacity:0;
		transition: opacity .25s ease 0.25s;
		// @include color('background', $c-white,1);
		// @include color('fill', $c-green-light,1);
		
		.logo-podge-full {
			margin-left:-100%; //centers the logo on navbar
		}

		svg {
			height: $navBar-H - 20px;
			width: auto;
		    margin-left: -90%;


			&:last-child {
				// display: none; // hides logo in nav bar
			}
		}
		
		@include bp(sm) {
			// padding: 12px 17px 8px 17px; //backup padding
			padding: 12px 0 8px 0;
			// border-bottom: 4px solid;
			// @include color('border-color', $c-green-pale,1);
			
			svg {
				height: $navBar-H * 1.5 + 20px;
				margin: 0 3px;
				margin-left:-100%;
			}
			
		}
		
		@include bp(md) {
			svg {
				margin-left:-100%;
			}
			svg:last-child {
				display: block;
			}
		}
	}

	.animateIN {
		opacity:1;
		transition: opacity .5s ease 0.25s;
		pointer-events: none;
	}

	// LOGO BACKUP
	// &__logo {
	// 	z-index: 999;
	// 	order: 1;
	// 	display: flex;
	// 	align-self: flex-start;
	// 	flex-direction: row;
	// 	justify-content: center;
	// 	padding: 10px;
	// 	// @include color('background', $c-white,1);
	// 	// @include color('fill', $c-green-light,1);
		
	// 	svg {
	// 		height: $navBar-H - 20px;
	// 		width: auto;
			
	// 		&:last-child {
	// 			display: none;
	// 		}
	// 	}
		
	// 	@include bp(sm) {
	// 		padding: 12px 17px 8px 17px;
	// 		// border-bottom: 4px solid;
	// 		// @include color('border-color', $c-green-pale,1);
			
	// 		svg {
	// 			height: $navBar-H + 3px;
	// 			margin: 0 3px;
	// 		}
			
	// 	}
		
	// 	@include bp(md) {
	// 		svg:last-child {
	// 			display: block;
	// 		}
	// 	}
	// }

	&__menu {
		z-index: 888;
		order: 2;
		width: 0; height: 0;
		@include bp(md) {
			flex-grow: 2;
			float: right;
			width: auto;
			height: $navBar-H;
			display: block;
		}
	}
	&__twitter {
		order: 2;
		// width: $navBar-H;
		// height: $navBar-H;
		@include bp(md) {
			order: 3;
		}		
	}
	&__toggle {
		position: absolute;
		top: 0; right: 0;
		order: 3;
		width: $navBar-H; height: $navBar-H;
		display:block;
		@include bp(md) {
			display: none;
		}
	}
}

//--------------------------------------------------------------------------
/*! - .nav-footer */
//--------------------------------------------------------------------------
.nav-footer {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding-bottom: 20px;
	
	font-size: 14px;
	@include color('background', $c-green-mint,1);
	@include color('color', $c-black,1);
	
	@include bp(sm) {
		flex-direction: row;
		align-content: stretch;
	align-items: stretch;
		max-height: 100px;
		font-size: 18px;
		padding-bottom: 0px;
	}
	
	&__scroll {
		position: absolute;
		bottom: 100%;
		left:50%;
		transform: translateX(-50%);
		height: 100px;
		// @include color('background', $c-orange,1);
		background-image:url('../img/bkgs/nav-footer__scroll.png');
		background-size:100% 100%;
		background-repeat:no-repeat;
	}
	&__col {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-content: center;
		align-items: center;
		width: 100%;
		padding: 20px 0 0 0;
		
		@include bp(sm) {
			width: 50%;
			padding: 20px;
			flex-direction: row;
			justify-content: flex-start;
			align-content: center;
			align-items: center;
		}
		
		&--podge {
			
		}
		
		&--tommy {
			justify-content: flex-end;
		}
		
		svg {
			height: 45px;
			margin: 0 15px;
			width: auto;
			@include color('fill', $c-white,1);
		}
	}
	
}

//==========================================================================
/*! .mnu */
// Manu modules and components
//==========================================================================

.nav-bar__brand {
	.mnu.mnu-main {
		// bullet point style
		&:before, &:after {
			content: '';
			position: absolute;
			top:45%;
			display: block;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			@include color('background', $c-black,1);
			
			@include bp(sm) {
				position:relative;
				width: 6px;
			}
			
			@include bp(md) {
				width: 6px;
				height: 6px;
			}
		}
		&:before {
			left:0;

			@include bp(sm) {
				// left:26%;
				margin-right:0;
			}
			@include bp(md) {
				left:0px;
			}
		}
		&:after {
			right:0;
			
			@include bp(sm) {
				// right:26%;
				margin-left:0;
			}
			@include bp(md) {
				right:0px;
			}
		}		
	}
}

.mnu {
	list-style: none;
	padding: 0;
	margin: 0;
}
.mnu-main {
	position: fixed;
	top: $navBar-H; left:0; right:0;
	height: 0;
	overflow: hidden;
// 	background: blue;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-content: center;
	@include color('background', $c-white,1);
	
	font-family: $f-f-title;
	font-size: 18px;
	transition: height 0.5s ease 0.25s;
	


	&.is-open { // issue on safari means calc() can't be used for the bootstrap collapse menu
		height:100%;
		padding-bottom:70px;
		// height: calc(100% - #{$navBar-H});
		transition: height 0.5s ease 0s;
	}
	
	@include bp(md) {
		position: relative;
		top:0; height: $navBar-H!important; //!important to stop .is-active triggering
		transition: height 0s ease 0s;
		flex-direction: row;
		justify-content: flex-end;
		align-content: stretch;
		align-items: stretch;
		flex-wrap: nowrap;
		
	}
	
	&__item {
		padding: 5px 0;
		text-align: center;
		opacity: 0;
		transition: opacity 0.25s ease 0s;

		height:$navBar-H; // fix for win10chrome50
		
		@include bp(md) {
			transition: opacity 0s ease 0s;
			opacity: 1!important;
			padding: 0;
		}
		
		.is-open & {
			transition: opacity 0.25s ease 0.25s;
			opacity: 1;
		}
	}
}


//==========================================================================
/*! .btn */
// User interactive elements and links
//==========================================================================
.btn {
	display: inline-block;
	
	transition: all 0.3s ease;

	* {
		transition: all 0.3s ease;
	}
}

//--------------------------------------------------------------------------
/*! - .btn-scrollDown */
//--------------------------------------------------------------------------
.btn-scrollDown {
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	
	opacity: 0.7;
	font-family: $f-f-title;
	font-size: 16px;
	
	@include color('color', $c-black,1);
	@include color('fill', $c-black,1);
	
	@include bp(sm) {
		font-size: 27px;
	}
	
	&__logo {
		display: block;

		width: 10px;
		height: 20px;
		margin: 0 10px;
		
		
		@include bp('sm') {
			// width: 86px;
			// height: 86px;
			width: 20px;
			height: 42px;
		}
	}
	
	html.desktop &:hover {
		opacity: 1;
		
		.btn-scrollDown__logo {
			transform: translateY(3px);
		}
	}
}

//--------------------------------------------------------------------------
/*! - .btn-scrollUp */
//--------------------------------------------------------------------------
.btn-scrollTop {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include color('fill', $c-black,1);
	padding: 15px 60px 0 60px;
	text-align: center;
	
	font-family: $f-f-title;
	font-weight: 700;
	
	&__icon {
		width: 20px;
		// transform: rotateZ(-90deg);
		display: block;
		margin-bottom: 5px;
		
		svg {
			display: block;
		}
	}
	html.desktop &:hover {
		.btn-scrollTop__icon {
			transform: rotateZ(-360deg);
		}
	}
}

//--------------------------------------------------------------------------
/*! - btn-slider */
//--------------------------------------------------------------------------
.btn-slider {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@include color('background', $c-black,1);
	width: 50px; height: 80px;
	position: absolute;
	top: 30%;
	// transform: translateY(-50%);
	transition: all 0.3s ease;
	
	@include bp(sm) {
		top:50%;
	}
	@include bp(sm) {
		top:30%;
	}
	
	@include bp(md) {
		width: 95px; height: 130px;
	}
	
	&__icon {
		@include color('fill', $c-white,1);
		display: block;
		width: 10px;
		
		svg {
			display: block;
		}
		
		@include bp(md) {
			width: 30px;
		}
		
		.btn-slider--left & {
			transform: rotateZ(180deg);
		}
	}
	
	&--left {
		left:0;
		border-radius: 0 100% 100% 0;
		
		
	}
	&--right {
		right: 0;
		border-radius: 100% 0 0 100%;
	}
	
	html.desktop &:hover {
		// @include color('background', $c-green-mint,1);
		
		&.btn-slider--left .btn-slider__icon {
			transform: translateX(-3px) rotateZ(180deg);
		}
		
		&.btn-slider--right .btn-slider__icon {
			transform: translateX(3px);
		}
	}
	html.desktop &--left:hover &__icon {
		
	}
	html.desktop &--left:hover &__icon {
		
	}
}

//--------------------------------------------------------------------------
/*! - .btn-menuMain */
//--------------------------------------------------------------------------
.btn-menuMain {
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	
	height: 100%;
	padding: 15px 20px;
	@include color('color', $c-black,1);
	
	font-size: 22px;
	
	@include bp(md) {
		font-size: 18px;
		width: 100%;
		// padding: 0 20px;
	}
	
	@media screen and (min-width: 992px) {
		font-size: 14px;
		padding: 0 8px;
	}

	@media screen and (min-width: 1100px) {
		font-size: 15px;
		padding: 0 8px;
	}	

	@media screen and (min-width: 1580px) {
		font-size: 20px;
		padding: 0 14px;
	}
	
	html.desktop &:not(.nohover):hover {
		@include color('color', $c-white,1);
		@include color('background', $c-green-mint,1);
	}
}

//--------------------------------------------------------------------------
/*! - .btn-cta */
//--------------------------------------------------------------------------
.btn-cta {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 25px 16px 10px;
	@include color('background', $c-white,1);
	border-radius: 10px;
	@include color('color', $c-green-reg,1);
	@include color('fill', $c-green-reg,1);
	font-size: 16px;
	line-height: 1;
	font-weight: 700;
	opacity: 0.9;
	
	@include bp(sm) {
		padding: 14px 20px 16px 20px;
		font-size: 16px;
	}
	
	&__icon {
		position: absolute;
		right: 15px;
		top:50%;
		transform: translateY(-50%);
		width: 12px;
		align-self: flex-end;
		
		svg {
			display: block;
		}
	}
	
	html.desktop &:hover {
		opacity: 1;
		
		.btn-cta__icon {
			transform: translateY(-50%) translateX(2px);
		}
	}
}

//--------------------------------------------------------------------------
/*! - .btn-menuToggle */
//--------------------------------------------------------------------------
.btn-menuToggle {
	cursor: pointer;
	display: block;
	position: absolute;
	top:22px; right:20px;
	width: 30px; height: 25px;
	
	border-top: 5px solid;
	border-bottom: 5px solid;
	@include color('border-color', $c-green-mint);
	
	@include bp('md') {
		display: none;
	}
	
	&:after, &:before {
		pointer-events: none;
		content: '';
		position: absolute;
		top:50%;
		transform: translateY(-50%) rotate(0deg);
		display: block;
		width: 100%;
		height: 5px;
		@include color('background-color', $c-green-mint);
		transition:all 0.25s ease;	
	}
	&.is-open {
		border: 0px;
		
		&:before {
			transform: translateY(-50%) rotate(-45deg);
		}
		&:after {
			transform: translateY(-50%) rotate(45deg);
		}
	}
}

//--------------------------------------------------------------------------
/*! - .btn-twitter */
//--------------------------------------------------------------------------
.btn-twitter {
	height: 70px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
	@include color('fill', #499EF1,1);
	width:44px;
	padding:0 10px;

	svg {
		height: 30px;
		width: auto;
		
		
		@include bp('lg') {
			height: 40px;
		}
	}
	
	html.desktop &:hover {
		@include color('fill', $c-white,1);
		@include color('background', $c-green-mint,1);
	}
}

//==========================================================================
/*! Slick Slider & Overrides */
//==========================================================================
@import 'partials/_slick';

// Fade out slides at edge of screens
// @include bp(md) {
// 	.slick-slider .slick-list, .slick-slider .slick-track {
// 		z-index: 0;
// 		&:before, &:after {
// 			content: '';
// 			position: absolute;
// 			top:0;
// 			z-index: 100;
// 			width: 5%; height: 100%;
// 		}
// 		&:before {
// 			left:0;
// 			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fbfbfb+0,fbfbfb+100&0.5+80,0+100 */
// 			background: -moz-linear-gradient(left, rgba(251,251,251,0.5) 0%, rgba(251,251,251,0.5) 80%, rgba(251,251,251,0) 100%); /* FF3.6-15 */
// 			background: -webkit-linear-gradient(left, rgba(251,251,251,0.5) 0%,rgba(251,251,251,0.5) 80%,rgba(251,251,251,0) 100%); /* Chrome10-25,Safari5.1-6 */
// 			background: linear-gradient(to right, rgba(251,251,251,0.5) 0%,rgba(251,251,251,0.5) 80%,rgba(251,251,251,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// 			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80fbfbfb', endColorstr='#00fbfbfb',GradientType=1 ); /* IE6-9 */
// 		}
// 		&:after {
// 			right: 0;
// 			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fbfbfb+0,fbfbfb+100&0+0,0.5+20 */
// 			background: -moz-linear-gradient(left, rgba(251,251,251,0) 0%, rgba(251,251,251,0.5) 20%, rgba(251,251,251,0.5) 100%); /* FF3.6-15 */
// 			background: -webkit-linear-gradient(left, rgba(251,251,251,0) 0%,rgba(251,251,251,0.5) 20%,rgba(251,251,251,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
// 			background: linear-gradient(to right, rgba(251,251,251,0) 0%,rgba(251,251,251,0.5) 20%,rgba(251,251,251,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
// 			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fbfbfb', endColorstr='#80fbfbfb',GradientType=1 ); /* IE6-9 */
// 		}
// 	}
// }

//==========================================================================
/*! BUG FIXES & BROWSER UPGRADE */
//==========================================================================
.browserUpgrade {
	position: fixed;
	top: 0; left: 0; right:0;
	z-index: 666;
	width: 100%;
	@include color('background', $c-green-light, 1);
	border-bottom: 2px solid;
	@include color('border-color', $c-green-reg,1);
	opacity: 0.9;
	padding: 90px 40px 20px 300px;
	
	@include color('color', $c-white,1);
	font-size: 14px;
	
	display: none;
	
	&__close {
		position: absolute;
		bottom: 20px; right: 20px;
		display: block;
		@include color('background', $c-green-reg,1);
		border-radius: 50%;
		width: 15px; height: 15px;
		font-size: 8px;
		font-weight: 700;
		line-height: 1;
		text-align: center;
		padding-top: 0.38em;
		text-decoration: none!important;
		cursor: pointer;
		
		html.desktop.ie8 &, 
		html.desktop.ie9 & {
			display: none;
		}
	}
	
	html.desktop.ie8 &, 
	html.desktop.ie9 &,
	html.desktop.ie10 &,
	html.desktop.ie11 &, 
	html.desktop.safari8 &,
	html.desktop.safari7 &,
	html.desktop.firefox49,
	html.desktop.firefox48,
	html.desktop.firefox47,
	html.desktop.chrome55,
	html.desktop.chrome54,
	html.desktop.chrome53,
	html.mobile.safari6,
	html.mobile.safari6,
	html.mobile.safari5
	html.mobile.safari4 {
		display: block;
	}
	
	html.desktop.ie8 &, 
	html.desktop.ie9 & {
		z-index: 9999;
		bottom:0;
		opacity: 1;
		padding: 200px 300px 20px 300px;
		text-align: center;
		font-size: 18px;
		border: none;
	}
	
	a {
		text-decoration: underline;
	}
}

	

html.desktop.safari {
	
	@include bp(md) {
		.btn-menuMain {
			height: $navBar-H;
		}
	}
	
	&8, &9 {
		@include bp(sm) {
			.con-supportPod__inner {
				height: initial;
			}
		}
	}
}

html.iphone.safari {
	
	&7, &8 {
		
		.con-supportPod__inner {
				height: initial!important;
		}
		.btn-cta__icon {
			display: none;
		}
	}
}

html.desktop.ie {
	&10, &11 {
		.nav-bar__logo svg {
			height: 50px;
			width: 48px;
			
			@include bp(sm) {
				height: 73px;
				width: 70px;
			}
			
			@iclude bp(md) {
				&:last-child {
					width: 122px;
				}
			}
			
		}
		
		.nav-footer .svg-logo-podge-mini {
			width: 45px;
		}
		
		.nav-footer .svg-logo-tommy {
			width: 121px;
		}
		
		.nav-footer .btn-scrollTop {
			position: relative;
			height: 80px;
			padding: 30px 30px 0 30px;
		}
		
		.nav-footer .btn-scrollTop svg {
			width: 33px;
			height: 20px;
		}
		
		.sec-header__logo svg {
			height: 60vh;
			margin-top: 5vh;
			margin-bottom: auto;
		}
		
		.sec-event__map svg {
			height: 28vw;
		}
		
		@include bp(md) {
			.con-supportPod { 
				padding-top: 40px;
				padding-bottom: 40px;
			}
			.con-supportPod__cap {
				height:200px;
				
				&:before {
					display: none;
				}
				&:after {
					height: 400px;
					width: 100%;
					padding: 0;
				}
				
				&--top {
					&:after {
						top:-40px;
					}
				}
				&--bottom {
					order: 3;
					&:after {
						bottom:-40px;
					}
				}
			}
		}
	}
}