//mix-color
	//Takes a 'property' and a 'hex' color arg. 
	//If an 'alpha' arg is applied it will convert the output to rgba
@mixin color($prop, $hex, $alpha:1) {
	#{$prop}: $hex;//fallback color for non rgba
	@if $alpha < 1 { //If the alpha value is less than 1
		#{$prop}: rgba($hex, $alpha);
	}
}

// =================================================================================================
// Bootstrap 3 Mixnins and utilities
// =================================================================================================

//Breakpoint storage and tracking
$default-breakpoint: root;//Set root (xs)
$current-breakpoint: $default-breakpoint;//Store current Bp from @include bp()

$breakpoints:( //Bootstrap 3 breakpoints (XS = min-size 0px)
//	BP 		MIN-SIZE
	'sm': 		750px, //Tablets
	'md': 		992px, //Small Desktop
	'lg': 		1200px, //Large Desktop
	'x-lg': 	1500px //For much Larger Desktop
);
//Breakpoint Mixin
@mixin bp($min) {
	// Get the width from the keyword `$breakpoint`
	// Or `null` if the keyword doesn't exist in `$breakpoints` map
	$bp: map-get($breakpoints, $min);

	// If `$breakpoint` exists as a key in `$breakpoints`
	@if $bp != null {
		// Update `$current-breakpoint`
		$current-breakpoint: $min !global;
		
		// Open a media query block
		@media (min-width: $bp) {
			// Let the user dump content
			@content;
			}

		// Then reset `$current-breakpoint` to `$default-breakpoint` (root)
		$current-breakpoint: $default-breakpoint !global;
	}
	
	// If `$breakpoint` doesn't exist in `$breakpoints`, 
	// Warn the user and do nothing
	@else {
		@warn "Invalid breakpoint `#{$breakpoint}`.";
	}
}

//Hiding classes
	//Eeach loop creates a .BP-hide-down and a .BP-hide-up class based on your $breakpoints.
	// .xs-hide-down, .sm-hide-up, .sm-hide-down, .md-hide-up ...etc 

$prevbp: 'xs';//Store previous alias (Set to bootstrap Extra Small by default)
@each $breakpoint in $breakpoints {
	$alias: nth($breakpoint, 1);
	$min-size:nth($breakpoint, 2);
	
	//Create a hide-down from the previous BP but using current bp (min-width - 1)
	.#{$prevbp}-hide-down {//Use previous alias for class name
		@media only screen and (max-device-width: $min-size - 1) { display:none; }// -1 to 
	}
	
	//Create hide-up bp for current BP from min-width
	.#{$alias}-hide-up {
		@media only screen and (min-device-width: $min-size) { display:none; }
	}
	
	$prevbp: $alias;//Update Previous alias
}

//Placeholder in Media Query handler
	//Allows use of placeholder extension inside Breakpoints
$placeholders: ();// Store existing placeholdersnames


//Crete a placeholder
@mixin ph($name) {
	@if not index($placeholders, $name) {// If placeholder doesn't exist yet
		$placeholders: append($placeholders, $name) !global;// Store the name
		
		@at-root {
			@each $breakpoint, $bp in $breakpoints {// Looping through all the breakpoints
				@media (min-width: $bp) {// Opening a media query
					%#{$name}-#{$breakpoint} {// Generating a placeholder at root level
						@content;// With desired content
					}
				}
			}
			%#{$name}-#{$default-breakpoint} {// And dumping a placeholder out of any media query
				@content;
			}
		}
	}
	@else {
		@warn "Placeholder `#{$name}` already exists.";// If placeholder already exist, warn the user
	}
}
//Extend to a placeholder 
@mixin ext($name) {
	@extend %#{$name}-#{$current-breakpoint} !optional;
}

// =================================================================================================